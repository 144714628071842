/* STYLES FOR HEADER SECTION*/
.header-wrapper {
  width: 100%;
  background-color: rgba(18, 9, 29, 1) !important;
  box-shadow: none !important;
  position: relative !important;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 16px 96px !important;
  position: relative;
}

.header-logo {
  height: 36px;
}

.language-selection-stack {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.burger-menu {
  display: none;
}

.header-middle-section {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 27px;
}

.header-middle-section a {
  text-decoration: none;
}

.header-middle-section p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 400;
}

.terms-of-conditions {
  padding: 100px;
}

.terms-of-conditions-header {
  text-align: center;
}

.header-right-section {
  display: flex;
  align-items: center;
}

.language-selection {
  display: flex !important;
  align-items: center !important;
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-transform: none !important;
}

.language-selection-icon {
  margin-right: 10px !important;
}

.login-button {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-transform: none !important;

}

.demo-button {
  color: white !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  background-color: #6257FF !important;
  padding: 8px 16px 8px 16px !important;
  border-radius: 50px 50px 50px 50px !important;
  width: fit-content;
}

@media (max-width: 899px) {
  .burger-menu {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .burger-menu-icon {
    color: white !important;
  }

  .header-container {
    padding: 16px 0 16px 12px !important;
  }

  .header-middle-section {
    display: none !important;
  }

  .header-right-section {
    display: none !important;
  }
}

/* STYLES FOR MAIN SECTION */
.main-section {
  background-color: rgba(18, 9, 29, 1);
  background-image:
    linear-gradient(to bottom,
      rgba(18, 9, 29, 1) 0%,
      rgba(18, 9, 29, 0) 50%),
    url("../../assets/img/planet.png");
  background-size: cover;
  background-position: center 150px;
  background-repeat: no-repeat;
  padding: 20px 20px 0 20px;
}

.main-section-title {
  text-align: center;
  width: 100%;
  font-size: 60px !important;
  font-weight: 700 !important;
  line-height: 67px !important;
  color: white !important;
}

.main-tabs-list {
  overflow-x: auto;
}

.main-tabs-list .MuiTab-root {
  min-width: fit-content;
  padding: 12px 16px;
}

.main-tabs-list::-webkit-scrollbar {
  height: 6px;
}

.main-tabs-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.main-tabs-list .MuiTabs-flexContainer {
  display: flex;
  justify-content: center;
}

.main-tabs-list .MuiTabs-indicator {
  background-color: white;
  height: 1px;
}

.main-tabs-divider {
  height: 1px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0)) !important;
}

.active-tab .main-tabs::after {
  transform: scaleX(1);
}

.main-tab-label-title {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-transform: none !important;
  text-align: center !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.active-tab .main-tab-label-title {
  color: white !important;
}

.main-tab-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px !important;
}

.main-tab-panel {
  display: none;
}

.main-tab-panel.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px !important;
}

.main-tab-description {
  width: 50%;
  color: rgba(255, 255, 255, 0.72) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center;
}

.main-tab-image {
  height: auto;
  width: 50%;
  margin: 20px 20px 0 20px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0;
  transform: translateX(10px);
}

.main-tab-image.active {
  opacity: 1;
  transform: translateX(0);
}

.icon-row {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -2%;
  z-index: 2;
  gap: 28px;
}

.omnichannel-icon {
  width: 68px;
  height: 68px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin-top: 50px;
}

.main-tab-image-1 {
  position: relative;
  z-index: 1;
}

.main-tab-image-2 {
  position: absolute;
  top: 37%;
  left: 11%;
  z-index: 2;
  width: 40%;
}

.main-tab-image-3 {
  position: absolute;
  left: 65%;
  /* Adjust the horizontal position */
  z-index: 2;
  width: 17%;
  /* Adjust the width percentage of the third image */
  top: 22%;
}

@media (max-width: 899px) {
  .main-section {
    background-position: center 220px;
  }

  .main-tabs-list .MuiTabs-flexContainer {
    display: flex;
    justify-content: flex-start;
  }  

  .main-section-title {
    font-size: 40px !important;
    line-height: 44.64px !important;
  }

  .main-tab-label-title {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
  }

  .main-tab-image {
    height: auto;
    width: 100%;
  }

  .main-tab-panel.active {
    min-height: 400px !important;
  }

  .main-tab-description {
    width: 100%;
  }

  .image-container {
    margin-top: 20px;
  }

  .icon-row {
    gap: 18px;
    top: 2%;
  }

  .omnichannel-icon {
    width: 36px;
    height: 36px;
  }

  .main-tab-image-2 {
    top: 41.7%;
    left: -11%;
    z-index: 2;
    width: 70%;
  }

  .main-tab-image-3 {
    position: absolute;
    left: 68%;
    /* Adjust the horizontal position */
    z-index: 2;
    width: 30%;
    /* Adjust the width percentage of the third image */
    top: 28.7%;
  }
}

/* STYLES FOR GET A DEMO */
.get-a-demo-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(to bottom,
      rgba(12, 11, 15, 1) 0%,
      /* Start color */
      rgba(9, 13, 29, 1) 100%
      /* End color */
    );
  padding: 90px 96px !important;
  gap: 20px !important;
}

.get-a-demo-section h3 {
  color: white !important;
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
}

.get-a-demo-section h6 {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 0.72) !important;
}

.get-a-demo-section a {
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: #6257FF !important;
  padding: 8px 16px 8px 16px !important;
  border-radius: 50px 50px 50px 50px !important;
  width: fit-content;
}

@media (max-width: 899px) {
  .get-a-demo-section {
    padding: 90px 12px !important;
    gap: 20px !important;
  }

  .get-a-demo-section h3 {
    text-align: start;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 42px !important;
  }

  .get-a-demo-section h6 {
    text-align: start;
  }
}

/* STYLES FOR BENEFITS */
.benefits-section {
  background-color: rgba(9, 13, 29, 1) !important;
  padding: 60px 96px !important;
  border-radius: 0px 0px 32px 32px !important;
}

.benefits-grid {
  padding-bottom: 96px;
}

.benefits-tabs-section {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: space-around;
}

.benefits-tabs-section .MuiChip-root {
  padding: 12px !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  width: fit-content;
}

.benefits-title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.benefits-title .MuiChip-root {
  padding: 12px !important;
  color: rgba(255, 255, 255, 1) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  width: fit-content;
}

.benefits-title h4 {
  color: white;
  font-size: 36px;
  font-weight: 700;
}

.benefits-tabs-list {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.benefits-tabs-list h5 {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  text-transform: none !important;
  color: white !important;
  text-align: start;
}

.benefits-tabs-list h6 {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-transform: none !important;
  color: rgba(255, 255, 255, 0.72) !important;
  width: 100%;
}

.benefits-tabs-list button {
  max-width: none !important;
  text-align: left;
  padding-left: 0;
}

.benefits-tab {
  padding-bottom: 10px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.17) !important;
}

.benefits-tab.active {
  border-bottom-color: rgba(255, 255, 255, 0.9) !important;
}

.benefits-tab-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.benefits-tab-image {
  width: 100%;
  height: auto;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, filter 0.5s ease-in-out;
  opacity: 0;
  filter: blur(5px) brightness(50%);
}

.benefits-tab-image.ai-agents {
  width: 50%;
}

.benefits-tab-image.active {
  opacity: 1;
  filter: blur(0) brightness(100%);
}

.benefits-tabs-list .MuiTabs-indicator {
  background-color: white;
  height: 1px;
}

.benefits-tabs-list .MuiTab-root {
  -webkit-align-items: flex-start;
}

.outcomes-wrapper {
  display: flex;
  flex-direction: column;
}

.outcomes-wrapper h4 {
  color: white;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}

.outcomes-card {
  border-radius: 24px !important;
}

.outcomes-grid {
  padding: 48px 100px !important;
}

.outcomes-grid-item {
  padding: 0 !important;
}

.outcomes-grid-item h4 {
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 62px !important;
  color: black !important;
}

.outcomes-grid-item h6 {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: center;
}

@media (max-width: 899px) {
  .benefits-section {
    padding: 0 12px 60px 12px !important;
  }

  .benefits-tabs-list h6 {
    font-size: 14px !important;
  }

  .outcomes-grid {
    gap: 60px !important;
  }
}

/* STYLES FOR TESTIMONIALS */
.testimonials-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 96px !important;
  background-color: rgba(244, 245, 246, 1);
}

.testimonials-section h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 63px;
}

.testimonials-card {
  border-radius: 24px !important;
  padding: 28px !important;
  box-shadow: none !important;
}

.testimonials-card-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 0 !important;
  gap: 32px
}

.testimonials-card .MuiCardContent-root {
  padding-left: 0;
  padding-right: 0;
}

.testimonials-card-content {
  min-height: 240px;
}

.testimonials-card-actions {
  display: flex;
  align-items: center;
  padding: 0 !important;
}

@media (max-width: 899px) {
  .testimonials-section {
    padding: 60px 12px !important;
  }
}

/* STYLES FOR TRY FOR FREE */
.try-for-free-section {
  padding: 60px 96px;
  background-color: rgba(244, 245, 246, 1);
}

.try-for-free-card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: rgba(22, 14, 32, 1) !important;
  box-shadow: none !important;
  border-radius: 24px !important;
}

.try-for-free-card-left-content {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  padding: 60px !important;
  gap: 12px !important;
}

.try-for-free-card-left-content h3 {
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
  color: white !important;
}

.try-for-free-card-left-content h6 {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  color: white !important;
}

.try-for-free-card-left-content a {
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: rgba(141, 43, 255, 1) !important;
  padding: 8px 16px 8px 16px !important;
  border-radius: 50px 50px 50px 50px !important;
  width: fit-content;
}

.try-for-free-card-right-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.try-for-free-card-right-content img {
  position: relative;
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: -100px !important;
}

@media (max-width: 899px) {
  .try-for-free-section {
    padding: 60px 12px;
  }

  .try-for-free-card {
    min-height: 500px;
  }

  .try-for-free-card-left-content {
    padding: 28px 24px 28px 24px !important;
    z-index: 2;
  }

  .try-for-free-card-left-content h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 43px !important;
  }

  .try-for-free-card-right-content img {
    position: absolute;
    top: 200px;
    right: -50px;
    z-index: 0;
  }
}

/* STYLES FOR BLOG */
.blog-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 96px;
  background-color: rgba(244, 245, 246, 1);
}

.blog-section h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 63px;
}

.blog-section h6 {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(22, 14, 32, 0.68) !important;
}

.blog-section button {
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: #6257FF !important;
  padding: 8px 16px 8px 16px !important;
  border-radius: 50px 50px 50px 50px !important;
  width: fit-content;
  margin: 16px 0 32px 0;
}

.blog-card {
  display: flex;
  flex-direction: column;
  border-radius: 24px !important;
  box-shadow: none !important;
  cursor: pointer;
  padding: 20px;
  gap: 16px;
}

.blog-card img {
  width: 100%;
  height: 250px; 
  object-fit: cover; 
  border-radius: 20px !important;
}

.blog-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 0 20px 0 !important;
}

.blog-content div {
  width: fit-content;
  /* background-color: rgba(241, 246, 255, 1); */
  padding: 8px;
  color: rgba(77, 104, 129, 1);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
}

.blog-content h6 {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  color: black !important;
  min-height: 85px !important;
}

.blog-content p {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(22, 14, 32, 0.68) !important;
  min-height: 75px !important;
}

.blog-content span {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  color: rgba(107, 114, 128, 1) !important;
}

.blog-type {
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: rgba(229, 237, 255, 1);
  border-radius: 10px;
  padding: 0 5px 0 5px;
  margin-bottom: 16px !important;
}

.blog-type-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: rgba(66, 56, 157, 1) !important;
}

.blog-title {
  font-size: 28px !important;
  font-weight: 500 !important;
  line-height: 31.36px !important;
  letter-spacing: 0.46px !important;
}

.blog-subtitle {
  margin-top: 16px !important;
  font-size: 16px !important;
  font-weight: 200 !important;
  line-height: 24px !important;
  margin-bottom: 16px !important;
}

.blog-date {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: rgba(107, 114, 128, 1) !important;
}

.blog-page-container {
  padding: 87px 40px 40px 40px;
}

.blog-page-title {
  font-size: 38px !important;
  padding-bottom: 30px;
}

@media (max-width: 899px) {
  .blog-section {
    padding: 34px 12px 34px 12px;
  }

  .blog-section-title {
    font-size: 44px !important;
    line-height: 49px !important;
    font-weight: 600 !important;
  }

  .blog-section-subtitle {
    font-size: 18px !important;
    line-height: 27px !important;
    font-weight: 400 !important;
    text-align: center;
  }

  .blog-section-card-list {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    gap: 20px;
  }
}

/* STYLES FOR AWARDS */
.awards-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 96px;
  background-color: rgba(244, 245, 246, 1);
}

.awards-section h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 63px;
}

.awards-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px !important;
}

.awards-container h6 {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(22, 14, 32, 0.68) !important;
}

.awards-container span {
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: rgba(22, 14, 32, 0.68) !important;
}

.awards-container img {
  width: 272px;
  height: auto;
}

@media (max-width: 899px) {
  .awards-section {
    padding: 60px 12px;
    text-align: center;
  }

  .awards-section h3 {
    font-size: 36px;
    line-height: 47px;
  }
}

/* STYLES FOR FOOTER */
.footer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(22, 14, 32, 1) !important;
  padding: 60px 96px;
  gap: 20px;
}

.footer-section img {
  height: 40px;
}

.footer-links {
  gap: 32px;
}

.footer-links a {
  text-decoration: none !important;
}

.footer-links p {
  color: white !important;
  font-size: 14px !important;
  font-weight: 400;
}

.footer-rights {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(156, 163, 175, 1) !important;
}

@media (max-width: 899px) {
  .footer-section {
    padding: 60px 12px;
  }

  .footer-links {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    gap: 16px;
  }

  .footer-links p {
    font-size: 14px !important;
    font-weight: 500;
  }
}

/* STYLES FOR PRICING */
.plans-container {
  padding: 30px;
  background-color: #F4F5F6;
}

.plan-card-container {
display: flex;
justify-content: center;
}

.plan-card,
.plan-card-container,
.header-sticky {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.plan-card.hidden,
.plan-card-container.hidden,
.header-sticky.hidden {
  transform: translateY(-430px);
}

.plan-card.visible,
.plan-card-container.visible,
.header-sticky.visible {
  transform: translateY(0);
}

.plan-card-container {
  display: flex;
}

.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: none;
  z-index: 1000;
  display: flex;
}

.header-container-sticky {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.card-sticky {
  width: 25%;
  margin: 24px 24px 0 24px;
}

.price-header-container {
  transform: translateY(60px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.price-header-container.visible {
  transform: translateY(0);
}

.plan-price-header {
  font-size: 36px !important;
  display: flex !important;
  width: 100%;
  font-weight: 600 !important;
  color: black !important;
  padding-top: 15px;
  display: flex !important;
  align-items: baseline;
}

.comparison-table {
  margin-top: 20px !important;
  box-shadow: none !important;
}

.plan-card,
.table-cell {
  width: 25%;
}

.table-cell {
  background-color: rgba(243, 246, 251, 1);
}

.add-on {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: none !important;
}

.title-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
}

.header-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 30px 24px 0 0;
}

.header-title {
  font-size: 28px !important;
  font-weight: 600 !important;
  line-height: 42px !important;
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 18px;
  border: 1px solid lightgray;
  border-radius: 20px;
  width: 193px;
}

.switch-container-label {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  margin: 0 auto;
}

.card-plan-0 {
  gap: 16px !important;
  border-radius: 8px !important;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04) !important;
  height: fit-content;
  min-height: 378px;
}

.card-plan-1 {
  gap: 16px !important;
  border-radius: 8px !important;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04) !important;
  height: fit-content;
}

.card-plan-2 {
  gap: 16px !important;
  border-radius: 8px !important;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04) !important;
  height: fit-content;
}

.card-plan-3 {
  gap: 16px !important;
  border-radius: 8px !important;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04) !important;
  height: fit-content;
}

.plan-header-gradient-0 {
  height: 8px;
  background-color: rgba(141, 43, 255, 0.24);
}

.plan-header-gradient-1 {
  height: 8px;
  background-color: rgba(88, 193, 214, 0.24);
}

.plan-header-gradient-2 {
  height: 8px;
  background-color: rgba(104, 163, 252, 0.24);
}

.plan-header-gradient-3 {
  height: 8px;
  background-color: rgba(142, 133, 255, 0.24);
}

.plan-card {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  width: 100% !important;
}

.plan-name {
  font-size: 43px !important;
  display: flex !important;
  font-weight: 600 !important;
  width: 100%;
  padding-bottom: 12px;
  line-height: 42px !important;
}

.plan-price-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 63px;
  margin-top: 15px;
}

.plan-price-header-button-container {
  display: flex;
  align-items: center;
  height: 63px;
  margin-top: 15px;
}

.plan-price-header-button-container a, .plan-price-button-container a {
  color: white !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #6257FF !important;
  padding: 8px 16px 8px 16px !important;
  border-radius: 50px 50px 50px 50px !important;
  width: fit-content;
}

.plan-name-0 {
  color: black !important;
}

.plan-name-1 {
  color: rgba(88, 193, 214, 1) !important;
}

.plan-name-2 {
  color: rgba(104, 163, 252, 1) !important;
}

.plan-name-3 {
  color: rgba(142, 133, 255, 1) !important;
}

.plan-header {
  padding: 0 !important;
}

.plan-subtitle-wrapper {
  padding: 0 16px 16px 16px;
}
.plan-switch{
  background: white;
  height: 100%;
  display: flex;
  align-items: center;
  width: 130px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.plan-note{
  width: 80%;
  background: #6257FF;
  color: white;
  border-radius: 8px;
  border-top-right-radius: 0;
  padding: 8px;
}
.plan-switch .MuiSwitch-root{
  margin-left: 16px;
}
.plan-switch-label{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  background: #6257FF;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.plan-switch-label .switch-container-label{
  text-align: center;
  width: 100%;
  color: white !important;
}
.plan-price {
  font-size: 36px !important;
  display: flex !important;
  width: 100%;
  font-weight: 600 !important;
  color: black !important;
  padding-top: 15px;
  display: flex !important;
  align-items: baseline !important;
}

.plan-month {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #6B7280;
  margin-left: 6px;
}

.promotion-container-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background-color: rgba(22, 14, 32, 1) !important;
  box-shadow: none !important;
  border-radius: 24px !important;
  padding: 60px !important;
  margin-top: 30px;
}

.promotion-container-card h3 {
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
  color: white !important;
  text-align: center;
}

.promotion-container-card h6 {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  color: white !important;
  text-align: center;
}

.promotion-container-card span {
  color: #6581FF !important;
}

.promotion-container-card a {
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: #6257FF !important;
  padding: 8px 16px 8px 16px !important;
  border-radius: 50px 50px 50px 50px !important;
  width: fit-content;
}

@media (max-width: 600px) {
  .plans-container {
    padding: 30px 12px 30px 12px;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-pricing {
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 42px !important;
    text-align: center;
  }

  .subtitle-pricing {
    font-size: 18px !important;
    padding-top: 10px;
    text-align: center !important;
  }

  .promotion-container-card {
    padding: 28px 24px 28px 24px !important;
  }

  .promotion-container-card h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 43px !important;
  }
}

/* STYLES FOR BOOST CLIENT CHATS SECTION */
.boostClientChats-section {
  margin-left: 34px;
  margin-right: 34px;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: rgba(35, 35, 38, 1);
  border-radius: 32px;
  min-height: 392px;
}

.boostClientChats-left-content {
  padding-top: 73px !important;
  padding-left: 62px !important;
  padding-bottom: 73px !important;
}

.boostClientChats-title {
  font-size: 72px !important;
  font-weight: 500 !important;
  line-height: 72px !important;
  color: white !important;
}

.boostClientChats-subtitle {
  font-size: 20px !important;
  font-weight: 200 !important;
  line-height: 30px !important;
  color: white !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.boostClientChats-try-for-free-button {
  height: 42px;
  background-color: rgba(141, 43, 255, 1) !important;
}

.boostClientChats-right-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.boostClientChats-logomark-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: -50px !important;
}

/** Styles for FAQs **/
.faqs-section{
  padding: 60px 96px;
  background-color: rgba(244, 245, 246, 1);
}
@media (max-width: 899px) {
  .boostClientChats-section {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .boostClientChats-left-content {
    display: flex !important;
    flex-direction: column !important;
    padding: 34px !important;
    padding-left: 50px !important;
  }

  .boostClientChats-title {
    font-size: 44px !important;
    font-weight: 500 !important;
    line-height: 49.28px !important;
    text-align: center;
  }

  .boostClientChats-subtitle {
    text-align: center;
  }
}